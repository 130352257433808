<template>
    <div class="box">
        <el-form class="boxForm" :hide-required-asterisk="true">
            <el-form-item class="boxItem" :label="item.name" v-for="(item, index) in list" :key="index" status-icon>
                <el-select v-model="item.selectItem" value-key="value" size="small" filterable clearable>
                    <el-option
                        v-for="selectItem in item.select"
                        :key="selectItem.key" 
                        :label="selectItem.label" 
                        :value="selectItem"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <slot name="btn" class="slot"></slot>
    </div>
</template>

<script>
export default {
    props:{
        list:Array,
    },
    data() {
        return {
        }
    },
    created() {
        console.log('渲染关联情景')
    },
    destroyed(){
        console.log('销毁关联情景')
    },
    methods:{
    }
}
</script>

<style lang="scss" scoped>
.box /deep/ .boxForm{
    display: flex;
    flex-wrap: wrap;
    margin-bottom:30px ;
}
.box .boxForm /deep/ .boxItem{
    // margin-top:10px;
    display: flex;
    flex: 0 0 46%;
    margin: 0 2%;
}
.box .boxForm .boxItem /deep/ .el-form-item__label{
    // margin-top:10px;
    flex:0 0 70px;
    text-align-last: justify;

}

</style>