<template>
  <div style="display: flex; flex-direction: column; flex:auto; justify-content: center;">
      <DeviceKG v-slot:floot :isSWShow="KGstate">
            <el-button size="small" type="success" class="devButGreen" @click="$emit('InfraredControl','打开');KGstate=true">打开</el-button>
            <el-button size="small" type="success" class="devButGreen" @click="$emit('InfraredControl','关闭');KGstate=false">关闭</el-button>
      </DeviceKG>
  </div>
</template>

<script>
import DeviceKG from './deviceKG.vue'
export default {
    components:{
        DeviceKG
    },
    data() {
        return {
            KGstate:false,
        }
    },
    created() {
        console.log('渲染红外设备')
    },
    destroyed(){
        console.log('销毁红外设备')
    },
    methods:{
    }

}
</script>

<style>

</style>