<template>
  <div style="display: flex;flex-direction: column; flex:auto; justify-content: center;">
      <DeviceKG v-slot:floot :isSWShow="KGstate">
            <el-button size="small" type="success" class="devButGreen" @click="$emit('ProjectorControl','打开');KGstate=true">打开</el-button>
            <el-button size="small" type="success" class="devButGreen" @click="$emit('ProjectorControl','关闭');KGstate=false">关闭</el-button>
      </DeviceKG>
  </div>
</template>

<script>
import DeviceKG from './deviceKG.vue'
export default {
    components:{
        DeviceKG
    },
    data() {
        return {
            KGstate:false,
        }
    },
    created() {
        console.log('渲染投影仪')
    },
    destroyed(){
        console.log('销毁投影仪')
    },
    methods:{
        open(){

        },
        close(){
            
        }
    }

}
</script>

<style>

</style>