<template>
    <div style="flex: auto;" v-loading="loading">
        <keep-alive>
            <div class="box" style="display: flex;flex-direction: column; " v-if="isShow">
                <div class="head" v-if="list.length > 0">
                    <div v-for="(item) in list" :key="item.cpKeyValue" class="headButBox">
                        <span @click="clickScene(item)">
                            <el-button size="small" type="success" :loading="item.disabled?false:butLoading" :class="item.disabled?'disDevButGreen':'devButGreen'" :disabled="item.disabled">{{item.name}}</el-button>
                        </span>
                    </div>
                </div>
                <div class="floot">
                    <el-button size="small" type="primary" @click="isShow = false">情景设置</el-button>
                </div>
            </div>
            <FormItme :list="list" v-if="!isShow" >
                <template v-slot:btn>
                    <div class="btnBox">
                        <el-button size="small" type="success" class="devButGreen" @click="preservation">保存</el-button>
                        <el-button size="small" type="success" class="devButGreen" @click="isShow = true">返回</el-button>
                    </div>
                </template>
            </FormItme>
        </keep-alive>
    </div>
</template>

<script>
import FormItme from './formItme.vue'
import { linkageGetLinkageScene, sceneLinkageInfo, sceneLinkageSave, sceneModeControl } from '@/api/scene.js'
import { linkeSceneList } from './listOptions'

export default {
    props:{
        row:Object,
    },
    components:{
        FormItme
    },
    data() {
        return {
            loading:false,
            butLoading:false,
            isShow:true,
            list:JSON.parse(JSON.stringify(linkeSceneList)),
        }
    },
    created() {
        this.getAllScene()
        this.sceneLinkage()
        console.log('渲染关联情景')

    },
    destroyed(){
        console.log('销毁关联情景')
    },
    methods:{
        //获取所有选择得模式
        async getAllScene(){
            await linkageGetLinkageScene({
                sceneName:'',
                sceneAddress:'',
                hsIds:[this.row.hsId],
            })
            .then((res) => {
                let result = res.data.result
                console.log('linkageGetLinkageScene',res,result)
                let select = result.map(item=>{
                    return {
                        ...item,
                        key:item.jsSceneCategoryId,
                        label:item.jscName,
                        value:item.jsSceneCategoryId
                    }
                })
                this.list = this.list.map(item=>{
                    return {
                        ...item,
                        select,
                    }
                })
                console.log(this.list,'5555')
            }).catch((err) => {
                this.isError(err, this);
            });
        },
        sceneLinkage(){
            this.loading = true
            sceneLinkageInfo({
                hsId:this.row.hsId,
                deviceId:this.row.jourDeviceId
            }).then(res=>{
                let result = res.data.result
                console.log(result)
                for(let i in this.list){
                    this.list[i].disabled = true
                }
                for(let i = 0; i<result.length;i++){
                    for(let j = 0; j<this.list.length;j++){
                        if(result[i].jslAisle == this.list[j].cpKeyValue){
                            this.$set(this.list[j],'spdId',result[i].jsId)
                            this.$set(this.list[j],'selectItem',{value:result[i].jslSceneCategory})
                            this.$set(this.list[j],'disabled',false)
                            break
                        }
                    }
                }
                console.log(this.list)
                this.loading = false
            }).catch(err=>{
                this.loading = false
                this.isError(err,this)
            })
        },
        //保存
        preservation(){
            let arr = this.list.map(item=>{
                console.log(item,'item1111')
                return {
                    jscName:item.name,
                    jslAisle:item.cpKeyValue,
                    jslHouseId:this.row.hsId,
                    jslSceneCategory:item.selectItem.value?item.selectItem.value:null,
                    jslTriggerDevice:this.row.jourDeviceId,
                }
            })
            sceneLinkageSave(arr).then(res =>{
                this.$message.success("配对成功")
                this.isShow = true
                this.getAllScene()
                this.sceneLinkage()
            }).catch(err =>{
                this.isError(err,this)
            })
        },
        clickScene(data){
            console.log(data)
            if(data.disabled){this.$message.warning("未绑定场景");return}
            this.butLoading = true
            sceneModeControl({jsId:data.spdId}).then(res=>{
                this.$message.success("执行成功");
                this.butLoading = false
            }).catch(err=>{
                this.butLoading = false
                this.isError(err,this)
            })
        },
    },
    watch:{
        list:{
            handler(val){
                console.log(val);
            },
            deep:true
        }
    }
}
</script>

<style lang="scss" scoped>
@import './../../devButStyle.scss';
.box{
    .head{
        display: flex;
        // justify-content:center;
        flex-wrap:wrap ;
        .headButBox{
            margin-top:10px;
            flex:0 0 25%;
            text-align: center;
        }
    }
    .floot{
        display: flex;
        justify-content:center;
        margin-top:30px;
    }
    .btnBox{
        display: flex;
        justify-content: center;
    }
}
</style>