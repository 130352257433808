<template>
  <div class="box">
        <div class="airHeader" style="text-align:center">
        <p>设备已{{isSWShow?'开启':'关闭'}}</p>
            <div v-if="isSWShow" style="text-align:center;background:#63cf50;width: 100px;height: 100px;border-radius: 50%;margin: 0px auto;">
                <svg width="70" height="100" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" fill="#63cf50" fill-opacity="0.01"/><path d="M14.5 8C13.8406 8.37652 13.2062 8.79103 12.6 9.24051C11.5625 10.0097 10.6074 10.8814 9.75 11.8402C6.79377 15.1463 5 19.4891 5 24.2455C5 34.6033 13.5066 43 24 43C34.4934 43 43 34.6033 43 24.2455C43 19.4891 41.2062 15.1463 38.25 11.8402C37.3926 10.8814 36.4375 10.0097 35.4 9.24051C34.7938 8.79103 34.1594 8.37652 33.5 8" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M24 4V24" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>
            </div>
            <div v-else style="text-align:center;background:#9b9c9d;width: 100px;height: 100px;border-radius: 50%;margin: 0px auto;">
                <svg width="70" height="100" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" fill="#63cf50" fill-opacity="0.01"/><path d="M14.5 8C13.8406 8.37652 13.2062 8.79103 12.6 9.24051C11.5625 10.0097 10.6074 10.8814 9.75 11.8402C6.79377 15.1463 5 19.4891 5 24.2455C5 34.6033 13.5066 43 24 43C34.4934 43 43 34.6033 43 24.2455C43 19.4891 41.2062 15.1463 38.25 11.8402C37.3926 10.8814 36.4375 10.0097 35.4 9.24051C34.7938 8.79103 34.1594 8.37652 33.5 8" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M24 4V24" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>
            </div>
        </div>
        <div class="floot">
            <slot name="floot"></slot>
        </div>
  </div>
</template>

<script>
export default {
    props:{
        isSWShow:Boolean,
    },
    data() {
        return {
            // isSWShow:false,
        }
    },
    created() {
        console.log('渲染设备开关')
    },
    destroyed(){
        console.log('销毁设备开关')
    },
    methods: {
    },
}
</script>

<style lang="scss" scoped>
@import './../../devButStyle.scss';
.box{
    .head{
        display: flex;
        justify-content: center;
        .span{
            background:#63cf50;
            border-radius: 50%;
            padding:10px;
            .power{
                z-index: 999;
            }
        }
    }
    .floot{
        margin: 30px;
        display: flex;
        justify-content: center;
    }
}
</style>