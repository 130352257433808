<template>
  <div class="box">
    <div class="sidebar">
        <a :style="state=='A'?'background-color:#63cf50;color:#fff;':''" :class="[state!=='A'?'hoverSidebar':'','aSidebar']" @click="state='A'">空调</a>
        <a :style="state=='B'?'background-color:#63cf50;color:#fff;':''" :class="[state!=='B'?'hoverSidebar':'','aSidebar']" @click="state='B'">电视</a>
        <a :style="state=='C'?'background-color:#63cf50;color:#fff;':''" :class="[state!=='C'?'hoverSidebar':'','aSidebar']" @click="state='C'">投影仪</a>
        <a :style="state=='D'?'background-color:#63cf50;color:#fff;':''" :class="[state!=='D'?'hoverSidebar':'','aSidebar']" @click="state='D'">红外设备</a>
        <a :style="state=='E'?'background-color:#63cf50;color:#fff;':''" :class="[state!=='E'?'hoverSidebar':'','aSidebar']" @click="state='E'">关联情景</a>
        <a :style="state=='F'?'background-color:#63cf50;color:#fff;':''" :class="[state!=='F'?'hoverSidebar':'','aSidebar']" @click="state='F'">关联设备</a>
    </div>
        <div class="views">
            <transition name="component-fade" mode="out-in">
                <keep-alive>
                    <!-- 空调 -->
                    <AirConditioner
                    v-if="state==='A'"
                    :panelForm="panelForm"
                    @AirConditioner="airConditionerControl"/>
                    <!-- 电视 -->
                    <Television
                    v-if="state==='B'"
                    @TelevisionControl='televisionControl'/>
                    <!-- 投影仪 -->
                    <Projector 
                    v-if="state==='C'"
                    @ProjectorControl='projectorControl'/>
                    <!-- 红外设备 -->
                    <Infrared
                    v-if="state==='D'"
                    @InfraredControl='infraredControl'/>
                    <!-- 关联情景 -->
                    <RelationScene 
                    :row="row"
                    v-if="state==='E'"
                    @RelationSceneControl='relationSceneControl'/>
                    <!-- 关联设备 -->
                    <RelationEquipment 
                    :row="row"
                    v-if="state==='F'"
                    @RelationEquipmentControl='relationEquipmentControl'/>
                </keep-alive>
            </transition>
        </div>
  </div>
</template>

<script>
import AirConditioner from '../../components/airConditioner/airConditioner.vue';
import Television from './components/television.vue';
import Projector from './components/projectors.vue';
import Infrared from './components/infrared.vue';
import RelationScene from './components/relationScenes.vue';
import RelationEquipment from './components/relationEquipment.vue';
import { operationDevice } from '@/api/device.js'
export default {
    props:{
        row:Object,
        panelForm:Object,
    },
    components:{
        AirConditioner,
        Television,
        Projector,
        Infrared,
        RelationScene,
        RelationEquipment,
    },
    created() {
        console.log(this.row)
        console.log(this.panelForm)
    },
    data() {
        return {
            state:'A',
        }
    },
    methods: {
        //空调
        airConditionerControl(data,value){
            let fengSuControl = {
                '低速风':this.airWindSpeedLow,    
                '中速风':this.airWindSpeedMiddle, 
                '高速风':this.airWindSpeedHeight, 
            }
            let moShiControl = {
                '制冷模式':this.airCold,
                '制热模式':this.airHot, 
                '送风模式':this.airWind,
            }
            let switchControl = {
                '开':this.openLight,
                '关':this.closeLight,
            }
            let minusAddControl = {
                '加':this.airTemperatureAdd,
                '减':this.airTemperatureMinus,
            }
            if(fengSuControl[data]){
                fengSuControl[data]({
                    devId:this.row.jourDeviceId,
                    controlBit:"空调控制",
                    devAirEnable:"风速控制",
                })
            }else if(moShiControl[data]){
                moShiControl[data]({
                    devId:this.row.jourDeviceId,
                    controlBit:"空调控制",
                    devAirEnable:"模式控制",
                })
            }else if(switchControl[data]){
                switchControl[data]({
                    devId:this.row.jourDeviceId,
                    controlBit:"空调控制",
                    devAirEnable:"开关控制",
                })
            }else if(minusAddControl[data]){
                minusAddControl[data]({
                    devId:this.row.jourDeviceId,
                    controlBit:"空调控制",
                    devAirEnable:"温度控制",
                    devAirTemperature:value
                })
            }
            
        },
        //电视
        televisionControl(data){
            console.log(data)
            let obj1 = {
                '加音量':this.TVAddVolume,
                '减音量':this.TVMinusVolume,
            }
            let obj2 = {
                '音量开':this.TVONVolume,
                '音量关':this.TVOffVolume,
            }
            let obj3 = {
                '频道加':this.channelAdd,
                '频道减':this.channelMinus,
            }
            let obj4 = {
                '开机':this.powerOnTV,
                '关机':this.shutDownTV,
            }
            let obj5 = {
                '播放':this.playTV,
                '暂停':this.suspendTV,
            }
            console.log(Boolean(obj1[data]))
            if(obj1[data]){
                obj1[data]({
                    devId:this.row.jourDeviceId,
                    controlBit:"电视机控制",
                    televisionControl:"音量控制",
                    televisionControl2:"打开"
                })
            }else if(obj2[data]){
                obj2[data]({
                    devId:this.row.jourDeviceId,
                    controlBit:"电视机控制",
                    televisionControl:"静音控制",
                    televisionControl2:"打开"
                })
            }else if(obj3[data]){
                obj3[data]({
                    devId:this.row.jourDeviceId,
                    controlBit:"电视机控制",
                    televisionControl:"换台控制",
                    televisionControl2:"打开"
                })
            }else if(obj4[data]){
                obj4[data]({
                    devId:this.row.jourDeviceId,
                    controlBit:"电视机控制",
                    televisionControl:"开关控制",
                    televisionControl2:"打开"
                })
            }else if(obj5[data]){
                obj5[data]({
                    devId:this.row.jourDeviceId,
                    controlBit:"电视机控制",
                    televisionControl:"播放控制",
                    televisionControl2:"打开"
                })
            }
            
        },
        //投影仪
        projectorControl(data){
            return{
                '打开':this.projectorON,
                '关闭':this.projectorOFF,
            }[data]({
                devId:this.row.jourDeviceId,
                controlBit:"投影仪",
            })
        },
        //红外设备
        infraredControl(data){
            return {
                '打开':this.infraredON,
                '关闭':this.infraredOFF,
            }[data]({
                devId:this.row.jourDeviceId,
                controlBit:"红外设备",
            })
        },
        //关联情景
        relationSceneControl(data){

        },
        //关联设备
        relationEquipmentControl(data,row){
            if(!row.selectItem){this.$message.warning('请选择一条指令');return}
            return {
                '打开':this.equipmentON,
                '关闭':this.equipmentOFF,
            }[data]({
                devId:row.selectItem.value,
            })
        },
        equipmentON(data){
            let dataGinseng = {...data,devState:'开'}
            this.operationDevice(dataGinseng)
        },
        equipmentOFF(data){
            let dataGinseng = {...data,devState:'关'}
            this.operationDevice(dataGinseng)
        },
        //红外控制
        infraredON(data){
            let dataGinseng = {...data,infraredSwitch:'打开'}
            this.operationDevice(dataGinseng)
        },
        infraredOFF(data){
            let dataGinseng = {...data,infraredSwitch:'关闭'}
            this.operationDevice(dataGinseng)
        },
        //投影仪控制
        projectorON(data){
            let dataGinseng = {...data,infraredSwitch:'打开'}
            this.operationDevice(dataGinseng)
        },
        projectorOFF(data){
            let dataGinseng = {...data,infraredSwitch:'关闭'}
            this.operationDevice(dataGinseng)
        },
        //电视控制
        TVAddVolume(data){
            let dataGinseng = {...data,televisionControl2:'声音加'}
            this.operationDevice(dataGinseng)
        },
        TVMinusVolume(data){
            let dataGinseng = {...data,televisionControl2:'声音减'}
            this.operationDevice(dataGinseng)
        },
        TVONVolume(data){
            let dataGinseng = {...data,televisionControl2:'取消静音'}
            this.operationDevice(dataGinseng)
        },
        TVOffVolume(data){
            let dataGinseng = {...data,televisionControl2:'静音'}
            this.operationDevice(dataGinseng)
        },
        channelAdd(data){
            let dataGinseng = {...data,televisionControl2:'下一台'}
            this.operationDevice(dataGinseng)
        },
        channelMinus(data){
            let dataGinseng = {...data,televisionControl2:'上一台'}
            this.operationDevice(dataGinseng)
        },
        powerOnTV(data){
            let dataGinseng = {...data,televisionControl2:'打开'}
            this.operationDevice(dataGinseng)
        },
        shutDownTV(data){
            let dataGinseng = {...data,televisionControl2:'关闭'}
            this.operationDevice(dataGinseng)
        },
        playTV(data){
            let dataGinseng = {...data,televisionControl2:'播放继续'}
            this.operationDevice(dataGinseng)
        },
        suspendTV(data){
            let dataGinseng = {...data,televisionControl2:'播放暂停'}
            this.operationDevice(dataGinseng)
        },
        //空调控制
        airTemperatureMinus(data){
            this.operationDevice(data)
        },
        airTemperatureAdd(data){
            this.operationDevice(data)
        },
        openLight(data){
            let dataGinseng = {...data,devState:'打开'}
            this.operationDevice(dataGinseng)
        },
        closeLight(data){
            let dataGinseng = {...data,devState:'关闭 '}
            this.operationDevice(dataGinseng)
        },
        airWindSpeedLow(data){
            let dataGinseng = {...data,devAirWindSpeed:'低速风'}
            this.operationDevice(dataGinseng)
        },
        airWindSpeedMiddle(data){
            let dataGinseng = {...data,devAirWindSpeed:'中速风'}
            this.operationDevice(dataGinseng)

        },
        airWindSpeedHeight(data){
            let dataGinseng = {...data,devAirWindSpeed:'高速风'}
            this.operationDevice(dataGinseng)
        },
        airCold(data){
            let dataGinseng = {...data,devAirPattern:'制冷'}
            this.operationDevice(dataGinseng)
        },
        airHot(data){
            let dataGinseng = {...data,devAirPattern:'制热'}
            this.operationDevice(dataGinseng)
        },
        airWind(data){
            let dataGinseng = {...data,devAirPattern:'送风'}
            this.operationDevice(dataGinseng)
        },
        operationDevice(data){
            this.$emit('loading5',true)
            operationDevice(data).then(res=>{
                let result = JSON.parse(res.data.result)
                console.log(result)
                this.$message.success(`控制${result.msg}`)
                this.$emit('loading5',false)
            }).catch(err=>{
                this.$emit('loading5',false)
                this.isError(err,this)
            })
        }
    },
}
</script>

<style lang="scss" scoped>
@import './../devButStyle.scss';
.box{
    display: flex;
    flex-direction:row;
    flex:none;
    .sidebar{
        display: flex;
        flex-direction:column;
        .aSidebar{
            margin-bottom:10px;
        }
    }
    .views{
        display: flex;
        flex: auto;
        padding: 0 10px;
        height:400px;
        overflow-y:auto;
        flex-direction: column;
    }
    .el-button{
        margin: 1px 0;
    }
}

</style>