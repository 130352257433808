<template>
    <div v-loading="loading">
        <keep-alive>
            <div class="box" style="display: flex;flex-direction: column;" v-if="isShow">
                <div class="head">
                    <el-select v-model="selectEquipment" size="small" value-key="cpKeyValue" @change="selectChange" filterable clearable>
                        <el-option v-for="(item,index) in list" :key="index" :label="item.name" :value="item" :disabled="item.disabled" @click.native="isDis(item)" ></el-option>
                    </el-select>
                </div>
                <DeviceKG v-slot:floot :isSWShow="KGstate">
                    <el-button size="small" type="success" class="devButGreen" @click="$emit('RelationEquipmentControl','打开',selectEquipment);KGstate=true">打开</el-button>
                    <el-button size="small" type="success" class="devButGreen" @click="$emit('RelationEquipmentControl','关闭',selectEquipment);KGstate=false">关闭</el-button>
                </DeviceKG>
                <div class="floot">
                    <el-button size="small" type="primary" @click="isShow = false">设备设置</el-button>
                </div>
            </div>
            <FormItme ref="FormItme" :list="list" v-if="!isShow" #btn >
                <div class="btnBox">
                    <el-button size="small" type="success" class="devButGreen" @click="preservation">保存</el-button>
                    <el-button size="small" type="success" class="devButGreen" @click="isShow = true">返回</el-button>
                </div>
            </FormItme>
        </keep-alive>
    </div>
</template>

<script>

import FormItme from './formItme.vue'
import DeviceKG from './deviceKG.vue'
import { linkageLinkDevices, linkageSelectDevices } from '@/api/equipmentList'
import { linkeEquipmentList } from './listOptions'
const deng = [23,31];
const chuangLiang = [6];
const konTiao = [4];
export default {
    props:{
        row:Object,
    },
    components:{
        FormItme,
        DeviceKG
    },
    data() {
        return {
            KGstate:false,
            loading:false,
            isShow:true,
            list:JSON.parse(JSON.stringify(linkeEquipmentList)),
            arr1:[],
            arr2:[],
            arr3:[],
            selectEquipment:{},
        }
    },
    created() {
        this.getTableList()
        this.linkageSelectDevices()
        console.log('渲染关联设备')
    },
    destroyed(){
        console.log('销毁关联设备')
    },
    methods:{
        isDis(data){
            if(data.disabled){
                this.$message.warning('未绑定设备')
            }
        },
        selectChange(data){
            console.log(data)
        },
        linkageSelectDevices(){
            linkageSelectDevices({
                sn:this.row.devSn,              //设备唯一标识
                devId:this.row.jourDeviceId,           //设备id
            }).then(res=>{
                let result = res.data.result
                for(let i in this.list){
                    this.list[i].disabled = true
                }
                for (let [key, value] of Object.entries(result)) {
                    for(let j in this.list){
                        let item = this.list[j]
                        let keyArr = key.split('/')
                        let cpKeyValue = keyArr[0]
                        let devSn = keyArr[1]
                        console.log(item.cpKeyValue,cpKeyValue,devSn)
                        if(item.cpKeyValue == cpKeyValue){
                            console.log(cpKeyValue,item)
                            item.selectItem = {value,devSn}
                            item.disabled = false
                            break
                        }
                    }
                }
            }).catch(err=>{
                this.isError(err,this)
            })
        },
        preservation(){
            console.log(this.row)
            let co = sessionStorage.getItem("co")
            let arr = [] ;
            for(let i in this.list){
                let item = this.list[i]
                if(item.selectItem){
                    console.log(item)
                    arr.push({
                        sn:this.row.devSn,
                        linkageSn:item.selectItem.devSn,
                        actionType:1,
                        channel:item.cpKeyValue,
                        mac:this.row.devAuthSecret,
                        devId:item.selectItem.value,
                        co,
                    })
                }
            };
            linkageLinkDevices(arr,{
                mainSn:this.row.devSn
            }).then(res =>{
                let result = res.data.result
                if(result.data){
                    this.$message.warning(result.msg)
                    this.isShow = true
                    this.getTableList()
                    this.linkageSelectDevices()
                    return
                }
                this.$message.success('关联成功')
                this.isShow = true
                this.getTableList()
                this.linkageSelectDevices()
            }).catch(err =>{
                this.isError(err,this)
            })
        },
        getTableList(){
            this.loading= true
            this.$axios({
                url:`/common2/devices/listByHsId`,
                method: 'get',
                params:{
                    hsId:this.row.hsId
                }
            }).then((res)=>{
                let result = res.data.result
                console.log(result)
                this.arr1 = [];
                this.arr2 = [];
                this.arr3 = [];
                result.forEach(item=>{
                    if(deng.includes(item.devFirstType)){
                        if(item.devAuthSecret == this.row.devAuthSecret){
                            this.arr1.push(item)
                        }
                    }else if(chuangLiang.includes(item.devFirstType)){
                        if(item.devAuthSecret == this.row.devAuthSecret){
                            this.arr2.push(item)
                        }
                    }else if(konTiao.includes(item.devFirstType)){
                        if(item.devAuthSecret == this.row.devAuthSecret){
                            this.arr3.push(item)
                        }
                    }
                })
                console.log("arr1",this.arr1)
                console.log("arr2",this.arr2)
                console.log("arr3",this.arr3)
                this.list = this.list.map(item1=>{
                    let select = this.arrSect(item1.type).map(item2=>{
                        return {
                            ...item2,
                            key:item2.devSn,
                            label:`${item2.idftName?item2.idftName:''} ${item2.idstName?item2.idstName:''} ${item2.devNickname?item2.devNickname:''}`,
                            value:item2.jourDeviceId,
                        }
                    })
                    return {
                        ...item1,
                        select,
                    }
                })
                console.log(this.list)
                this.loading= false
            }).catch(err=>{
                this.loading= false
                this.isError(err,this)
            })
        },
        arrSect(type){
            return {
                '灯':this.arr1,
                '窗帘':this.arr2,
                '空调':this.arr3,
            }[type]
        },
    }
}
</script>

<style lang="scss" scoped>
@import './../../devButStyle.scss';
.box{
    .head{
        text-align:center;
    }
    .floot{
        display: flex;
        justify-content:center;
        margin-top:30px;
    }
    .btnBox{
        display: flex;
        justify-content: center;
    }
}
</style>