<template>
  <div class="box" style="flex-direction: column;flex:auto;justify-content: center;">
        <div class="imgBox">
            <AddOne @click="$emit('TelevisionControl','加音量')" class="item" theme="filled" size="48" fill="#63CF50" strokeLinecap="square"/>
            <ReduceOne @click="$emit('TelevisionControl','减音量')" class="item" theme="filled" size="48" fill="#63CF50" strokeLinecap="square"/>
            <img @click="$emit('TelevisionControl','音量开')" class="img item" title="音量开" src="http://pic-gongkai.fangzhizun.com/xc/public/2021-08-03 04:07:59/sound.png" >
            <img @click="clickPlay" class=" item playStop " :title="tvState?'暂停':'播放'" :src="tvState?stop:paly" >
            <!-- <Play @click="$emit('TelevisionControl','播放')" class="item" theme="filled" size="100" fill="#63CF50" strokeLinejoin="miter" strokeLinecap="butt"/> -->
            <img @click="$emit('TelevisionControl','音量关')" class="img item" title="音量关" src="http://pic-gongkai.fangzhizun.com/xc/public/2021-08-03 04:07:27/mute.png">
            <img @click="$emit('TelevisionControl','频道减')" class="img item prev" src="http://pic-gongkai.fangzhizun.com/xc/public/2021-08-03 04:07:48/prev.png">
            <img @click="$emit('TelevisionControl','频道加')" class="img item next" src="http://pic-gongkai.fangzhizun.com/xc/public/2021-08-03 04:07:33/next.png">
        </div>
        <div class="butBox">
            <el-button size="small" type="success" class="devButGreen" @click="$emit('TelevisionControl','开机')">开机</el-button>
            <el-button size="small" type="success" class="devButGreen" @click="$emit('TelevisionControl','关机')">关机</el-button>
        </div>
  </div>
</template>

<script>
import { AddOne ,ReduceOne ,Play } from '@icon-park/vue'
export default {
    data() {
        return {
            tvState:false,
            stop:'http://pic-gongkai.fangzhizun.com/xc/public/2021-08-03 04:08:03/stop.png',
            paly:'http://pic-gongkai.fangzhizun.com/xc/public/2021-08-03 04:07:43/play.png'
        }
    },
    components:{
        AddOne,
        ReduceOne,
        Play
    },
    created() {
        console.log('渲染电视')
    },
    destroyed(){
        console.log('销毁电视')
    },
    methods:{
        clickPlay(){
            this.tvState = !this.tvState;
            let state = this.tvState?'播放':'暂停'
            this.$emit('TelevisionControl',state)
        }
    }
}
</script>

<style lang="scss" scoped>
@import './../../devButStyle.scss';
.box{
    .imgBox{
        display:flex;
        flex-direction:row;
        justify-content:space-around;
        align-items:center;
        .img{
            width: 48px;
            height: 48px;
        }
        .playStop{
            width: 98px;
            height: 98px;
        }
        .prev{
             transform: rotateZ(90deg);
        }
        .next{
             transform: rotateZ(90deg);
        }
    }
    .butBox{
        display: flex;
        justify-content:center;
        margin-top:30px;
    }
}
</style>