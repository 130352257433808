<template>
  <div>
    <el-form-item class="airConditioner">
        <div class="airHeader">
            <p>目标温度调节℃</p>
            <div v-if="isOpenOrClose">
                <div class="wendu">
                    <svg @click="airTemperatureMinus" width="50" height="50" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" fill="white" fill-opacity="0.01"/><path d="M24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z" fill="#63cf50" stroke="#63cf50" stroke-width="4" stroke-linejoin="round"/><path d="M16 24L32 24" stroke="#FFF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        <span>{{devAirTemperature}}</span>
                    <svg @click="airTemperatureAdd" width="50" height="50" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" fill="white" fill-opacity="0.01"/><path d="M24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z" fill="#63cf50" stroke="#63cf50" stroke-width="4" stroke-linejoin="round"/><path d="M24 16V32" stroke="#FFF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M16 24L32 24" stroke="#FFF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>
                </div>
                <p class="p">已{{devState}}机 | {{devAirPattern}}模式 | {{devAirWindSpeed}}风</p>
                <p class="p">室内温度{{roomTemperature}}℃</p>
            </div>
            <div v-else>
                <div class="wendu">
                    <svg @click="airTemperatureMinus" width="50" height="50" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" fill="white" fill-opacity="0.01"/><path d="M24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z" fill="#9b9c9d" stroke="#9b9c9d" stroke-width="4" stroke-linejoin="round"/><path d="M16 24L32 24" stroke="#FFF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        <span style="color:#9b9c9d">{{devAirTemperature}}</span>
                    <svg @click="airTemperatureAdd" width="50" height="50" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" fill="white" fill-opacity="0.01"/><path d="M24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z" fill="#9b9c9d" stroke="#9b9c9d" stroke-width="4" stroke-linejoin="round"/><path d="M24 16V32" stroke="#FFF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M16 24L32 24" stroke="#FFF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>
                </div>
                <p class="p">关机状态</p>
                <p class="p">室内温度{{roomTemperature}}℃</p>
            </div>
        </div>
        <div class="button">
            <el-button type="info" style="width:100px" @click="$emit('AirConditioner','开');isOpenOrClose=true">开</el-button>
            <el-button type="info" style="width:100px" @click="$emit('AirConditioner','关');isOpenOrClose=false">关</el-button>
        </div>
        <!-- 操作 -->
        <div class="airSelect">
            <el-button type="info" @click="$emit('AirConditioner','低速风')" style="width:100px">低速风</el-button>
            <el-button type="info" @click="$emit('AirConditioner','中速风')" style="width:100px">中速风</el-button>
            <el-button type="info" @click="$emit('AirConditioner','高速风')" style="width:100px">高速风</el-button>
            <el-button type="info" @click="$emit('AirConditioner','自动风')" style="width:100px" v-if="panelForm.devSecondType != 27">自动风</el-button>
            <el-button type="info" @click="$emit('AirConditioner','制冷模式')" style="width:100px">制冷模式</el-button>
            <el-button type="info" @click="$emit('AirConditioner','制热模式')" style="width:100px">制热模式</el-button>
            <el-button type="info" @click="$emit('AirConditioner','送风模式')" style="width:100px">送风模式</el-button>
            <el-button type="info" @click="$emit('AirConditioner','自动模式')" style="width:100px" v-if="panelForm.devSecondType != 27">自动模式</el-button>
        </div>
    </el-form-item>
  </div>
</template>

<script>
export default {
    // props:['changestatus','changeStatus','panelForm'],
    props:{
        changestatus:Object,
        panelForm:Object,
        isOpenOrClose:Boolean,
        roomTemperature:String,
        devState:String,
        devAirWindSpeed:String,
        devAirPattern:String,
    },
    data() {
        return {
            time:null,
            max:null,
            min:null,
            devAirTemperature:26,
        }
        
    },
    created() {
        console.log(this.panelForm)
    },
    watch:{
        'panelForm.devSecondType':{
            handler: function(val, oldVal) {
                if(this.panelForm.airConditioner4.includes(val) || this.panelForm.chamberlainScene.includes(val)){
                    this.min = 16
                    this.max = 30
                }
            },
            immediate:true,
        },
    },
    methods: {
        airTemperatureMinus() {
            if (this.devAirTemperature > this.min) {
                this.devAirTemperature --
                if(this.time != null){
                    clearTimeout(this.time);
                }
                this.time = setTimeout(() => {
                    this.$emit("AirConditioner",'减',this.devAirTemperature)
                    clearTimeout(this.time)
                }, 500);
            }
        },
        airTemperatureAdd() {
            if (this.devAirTemperature < this.max) {
                this.devAirTemperature ++
                if(this.time != null){
                    clearTimeout(this.time);
                }
                this.time = setTimeout(() => {
                    this.$emit("AirConditioner",'加',this.devAirTemperature)
                    clearTimeout(this.time)
                }, 500);
                console.log(this.devAirTemperature)
            }
        },
        // 低速风
        airWindSpeedLow() {
            this.$emit("AirConditioner",'低速风')
        },
        // 中速风
        airWindSpeedMiddle() {
            this.$emit("AirConditioner",'中速风')
        },
        // 高速风
        airWindSpeedHeight() {
            this.$emit("AirConditioner",'高速风')
        },
        // 自动风
        airWindSpeedSelf() {
            this.$emit("AirConditioner",'自动风')
        },
        // 制冷模式
        airCold() {
            this.$emit("AirConditioner",'制冷模式')
        },
        // 制热模式
        airHot() {
            this.$emit("AirConditioner",'制热模式')
        },
        // 送风模式
        airWind() {
            this.$emit("AirConditioner",'送风模式')
        },
        // 自动模式
        airSelf() {
            this.$emit("AirConditioner",'自动模式')
        },
    },
}
</script>

<style scope>
.airConditioner .airHeader p{
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin:10px 0px;
}
.airConditioner .airHeader .wendu{
    text-align: center;
    font-size: 70px;
    color: #63cf50;
    font-weight: bold
}
.airConditioner .airHeader span{
    margin:0px 50px;
}
.airConditioner .airHeader svg{
    vertical-align: middle;
} 
.airConditioner .airHeader .p{
    color: #9b9c9d;
    margin: 0px 0px;
}
.airConditioner .button{
    text-align: center;
    margin: 10px 0px;
}
.airConditioner .button .el-button{
    background: #63cf50;
    text-align: center;
    /* font-weight: bold; */
    margin: 0px 10px;
    border:0px;
    font-size: 18px;
}
</style>