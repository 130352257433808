export const linkeEquipmentList = [
    { disabled:true, cpKeyValue: "01", name: "廊灯", type: "灯" },
    { disabled:true, cpKeyValue: "02", name: "夜灯", type: "灯" },
    { disabled:true, cpKeyValue: "03", name: "卫间灯", type: "灯" },
    { disabled:true, cpKeyValue: "04", name: "卫浴灯", type: "灯" },
    { disabled:true, cpKeyValue: "05", name: "房间灯", type: "灯" },
    { disabled:true, cpKeyValue: "06", name: "灯带", type: "灯" },
    { disabled:true, cpKeyValue: "07", name: "射灯", type: "灯" },
    { disabled:true, cpKeyValue: "08", name: "阅读灯", type: "灯" },
    { disabled:true, cpKeyValue: "09", name: "台灯", type: "灯" },
    { disabled:true, cpKeyValue: "0A", name: "阳台灯", type: "灯" },
    { disabled:true, cpKeyValue: "0B", name: "客厅射灯", type: "灯" },
    { disabled:true, cpKeyValue: "0C", name: "客厅筒灯", type: "灯" },
    { disabled:true, cpKeyValue: "0D", name: "客厅灯", type: "灯" },
    { disabled:true, cpKeyValue: "0E", name: "天花射灯", type: "灯" },
    { disabled:true, cpKeyValue: "0F", name: "筒灯", type: "灯" },
    { disabled:true, cpKeyValue: "10", name: "吊灯", type: "灯" },
    { disabled:true, cpKeyValue: "11", name: "衣帽灯", type: "灯" },
    { disabled:true, cpKeyValue: "12", name: "吧台灯", type: "灯" },
    { disabled:true, cpKeyValue: "13", name: "镜前灯", type: "灯" },
    { disabled:true, cpKeyValue: "14", name: "床头灯", type: "灯" },
    { disabled:true, cpKeyValue: "15", name: "左床头灯", type: "灯" },
    { disabled:true, cpKeyValue: "16", name: "右床头灯", type: "灯" },
    { disabled:true, cpKeyValue: "17", name: "落地灯", type: "灯" },
    { disabled:true, cpKeyValue: "18", name: "窗帘", type: "窗帘" },
    { disabled:true, cpKeyValue: "19", name: "客厅窗帘", type: "窗帘" },
    { disabled:true, cpKeyValue: "1A", name: "纱帘", type: "窗帘" },
    { disabled:true, cpKeyValue: "1B", name: "客厅窗纱", type: "窗帘" },
    { disabled:true, cpKeyValue: "1C", name: "卷帘", type: "窗帘" },
    { disabled:true, cpKeyValue: "1D", name: "排气扇", type: "窗帘" },
    { disabled:true, cpKeyValue: "29", name: "空调", type: "空调" },
]
export const linkeSceneList =[
    { name: "欢迎模式", spdId: "",disabled:true, cpKeyValue:1, selectItem:''},
    { name: "会客模式", spdId: "",disabled:true, cpKeyValue:2, selectItem:''},
    { name: "休闲模式", spdId: "",disabled:true, cpKeyValue:3, selectItem:''},
    { name: "阅读模式", spdId: "",disabled:true, cpKeyValue:4, selectItem:''},
    { name: "影院模式", spdId: "",disabled:true, cpKeyValue:5, selectItem:''},
    { name: "睡眠模式", spdId: "",disabled:true, cpKeyValue:6, selectItem:''},
    { name: "氛围模式", spdId: "",disabled:true, cpKeyValue:7, selectItem:''},
    { name: "全开模式", spdId: "",disabled:true, cpKeyValue:8, selectItem:''},
    { name: "全关模式", spdId: "",disabled:true, cpKeyValue:9, selectItem:''},
]